@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import "~bootstrap/scss/bootstrap";

/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */

body {
  background-color: #ecedf3 !important;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Montserrat', sans-serif !important;
}

.img-container {
  height: 60vh;
}

.img-container img {
  width: 100%;
  height: 100%;
}

.formhelper {
  font-size: 13px;
  padding: 15px 0px;
}

/* Input File Css  */

.img-upload-wrap {
  width: 100%;
  height: 20vh;
  border: 1px dashed #ccc;
  background-color: #ecedf3;
  z-index: 1;
  position: relative;
}

.inp-file {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
}

.browse-file-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}